// ナビゲーション
const navTrigger = document.getElementsByClassName('nav-trigger')[0];
const body = document.getElementsByTagName('body')[0];

navTrigger.addEventListener('click', toggleNavigation);

function toggleNavigation(event) {
  event.preventDefault();
  body.classList.toggle('nav-open');

  // 表示されているスクロールバーとの差分を計測し、 背面固定時はその差分body要素に余白を生成する
  const scrollbarWidth = window.innerWidth - document.body.clientWidth;
  document.body.style.paddingRight = toggleNavigation ? `${scrollbarWidth}px` : '';

  if (body.classList.contains('nav-open')) {
    TOP = document.documentElement.scrollTop || document.body.scrollTop;
    TOP *= -1;
    document.querySelector('body').style.position = 'fixed';
    document.querySelector('body').style.width = '100%';
    document.querySelector('body').style.top = TOP + 'px';
  } else {
    _top = Math.abs(TOP);
    document.querySelector('body').removeAttribute('style');
    window.scrollTo(0, _top);
  }
}
let listArray = document.querySelectorAll('.nav a, .overlay');
for (let i = 0; i < listArray.length; i++) {
  listArray[i].onclick = function () {
    body.classList.remove('nav-open');
    _top = Math.abs(TOP);
    document.querySelector('body').removeAttribute('style');
    window.scrollTo(0, _top);
  };
}

// スマホ画面回転で初期に戻す
window.addEventListener('orientationchange', function () {
  document.querySelector('body').removeAttribute('style');
  body.classList.remove('nav-open');
  console.log('回転');
});
